import React from 'react'
import { FixedScreen } from '@shared/components/fixed-screen'
import { ExternalPaths } from '@shared/menu-items/paths'
import { isProduction } from '@shared/pipes/config'
import cx from 'classnames'

import { StateProps } from './models'

import styles from './style.module.scss'

const options = [
	{
		url: isProduction ? ExternalPaths.investorLk : ExternalPaths.testInvestorLk,
		title: 'Я — инвестор',
		desc: 'Надёжные инвестиции, обеспеченные залогом недвижимости',
		buttonClass: styles.buttonGreen,
	},
	{
		url: isProduction ? ExternalPaths.personalLk : ExternalPaths.testPersonalLk,
		title: 'Я — заёмщик',
		desc: 'Быстрое и удобное финансирование для бизнеса и частных лиц',
		buttonClass: styles.buttonBlue,
	},
	{
		url: isProduction ? ExternalPaths.partnerLk : ExternalPaths.testPartnerLk,
		title: 'Я — партнёр',
		desc: 'Зарабатывайте на привлечении заёмщиков и инвесторов в Credit.Club',
		buttonClass: styles.buttonDarkGrey,
	},
]

export const View = ({ enabled, onClose }: StateProps) => (
	<FixedScreen transitionProps={{ in: enabled } as any} onClose={onClose}>
		<div className={styles.title}>Войдите в&nbsp;личный кабинет</div>
		{options.map(({ url, title, desc, buttonClass }) => (
			<div className={styles.loginItem} key={title}>
				<a
					className={cx(styles.button, buttonClass)}
					href={url}
					target='_blank'
					rel='noopener noreferrer nofollow'
				>
					{title}
				</a>
				<p>{desc}</p>
			</div>
		))}
	</FixedScreen>
)
