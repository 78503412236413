import React, { Fragment } from 'react'
import { loginOptions } from '@constants/login-options'
import cx from 'classnames'

import { IStateProps } from './model'

import styles from './style.module.scss'

export const View = ({ className, linksClassName }: IStateProps) => {
	return (
		<div className={cx(styles.wrap, className)}>
			<div>Войти в личный кабинет</div>
			<div className={cx(styles.link, linksClassName)}>
				{loginOptions.map(({ name, path }: { name: string; path: string }, idx: number) => (
					<Fragment key={path}>
						<a key={name} href={path} rel='noopener noreferrer nofollow' target='_blank'>
							{name}
						</a>{' '}
						{idx + 1 !== loginOptions.length && '|'}
					</Fragment>
				))}
			</div>
		</div>
	)
}
